import React from "react"
import "./navbar.css"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const activeStyles = {
    background: "#bd6f0a",
    color: "#fff"
}

// TODO - make navbar more accessible for super wide
const NavBar = () => {
    return (
        <div class="sidebar" role="banner">
            <Link to="/">
                <StaticImage class="profile"
                    src="../static/images/favicon.png"
                    alt="Peter Mason Profile"
                    placeholder="tracedSVG" />
            </Link>
            <div class="nav-wrap">
                <nav class="main-nav" role="navigation">
                    <ul class="unstyled list-hover-slide">
                        <li><Link to="/" activeStyle={activeStyles}>About</Link></li>
                        <li><Link to="/blog" activeStyle={activeStyles}>Blog</Link></li>
                        <li><Link to="/projects" activeStyle={activeStyles}>Projects</Link></li>
                        <li><Link to="/contact" activeStyle={activeStyles}>Contact</Link></li>
                    </ul>
                </nav>
            </div>
        </div >
    )
}

export default NavBar