import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { Helmet } from "react-helmet"
import NavBar from "./navbar"
import styled from "@emotion/styled"
import "../styles/normalize.css"
import { layout } from "./layout.module.css"


const Layout = ({ pageTitle, opacity, children }) => {
    const { backgroundImage } = useStaticQuery(
        graphql`
            query {
                backgroundImage: file(relativePath: {eq: "blue-sky.jpg"}) {
                childImageSharp {
                    gatsbyImageData(width: 4000)
                }
            }
        }`
    )

    const BackgroundOpacity = styled.div`
        background: ${opacity === 'high' ? 'rgb(255, 255, 255, 0.25)' : 'rgb(255, 255, 255, 0.05)'};
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    `

    const pluginImage = getImage(backgroundImage)

    return (
        <BgImage image={pluginImage} className={layout}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
            </Helmet>
            <NavBar></NavBar>
            <BackgroundOpacity>
                {children}
            </BackgroundOpacity>
        </BgImage>
    )
}

export default Layout